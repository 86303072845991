import React, { Component } from 'react'
import{
    Tabs,
    Tab
} from 'react-bootstrap'
import Axios from 'axios';
import * as URL from './URL.js'; 
import NumberFormat from 'react-number-format';

export default class DailyPnlTable extends Component {
    intervalID;
    constructor(props){
        super(props);
        this.state = {
            data: [],
            portfolios: []
        }
    }
    componentDidMount() {
        this.getData();
        this.intervalID = setInterval(this.getData.bind(this),60000);
    }
    getData=()=>{
        Axios.get(URL.GET_DAILY)
            .then(res => {
                console.log(res);
                console.log('DATA REFRESHED @'+Date.now());
                this.setState({data: res.data});
            });
    }
    componentWillUnmount(){
        clearInterval(this.intervalID);
    }
    headers=()=>{
        var i;
        var headers=[<th></th>];
        for (i=0; i<24; i++){
            headers.push(<th>HE{i+1}</th>)
        }
        return headers
    }
    rowData=(data,invert=false)=>{
        var elements=[];
        elements=data.map(item => {
            if(item>0 && invert===false)
                return <td style={{color: '#0be044'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></td>
            else if(item<0 && invert===false)
                return <td style={{color: 'red'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></td>
            else if (item>0 && invert===true)
                return <td style={{color: 'red'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></td>
            else if (item<0 && invert===true)
                return <td style={{color: '#0be044'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></td>
            return <td><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></td>

        });
        return elements
    }
    totalData=(item,invert=false)=>{
        if(item>0 && invert===false)
            return <span style={{color: '#0be044'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        else if(item<0 && invert===false)
            return <span style={{color: 'red'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        else if (item>0 && invert===true)
            return <span style={{color: 'red'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        else if (item<0 && invert===true)
            return <span style={{color: '#0be044'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        return <span><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
    }
    pnlTable=(port)=>{
        return (
                <div class='pnltable'>
                    <div class='d-flex'>
                        <div class='p-2 flex-fill'><h1>{port.note}</h1></div>
                        <div class='p-2 align-content-end'><h3>Total Cost: {this.totalData(port.cost,true)}</h3></div>
                        <div class='p-2 align-content-end'><h3>Total Revenue: {this.totalData(port.revenue)}</h3></div>
                        <div class='p-2 align-content-end'><h3>Total Profit: {this.totalData(port.active_profit)}</h3></div>
                    </div>
                    <table class='table table-hover'>
                        <thead>
                            <tr>
                                {this.headers()}
                            </tr>
                        </thead>
                        <tr>
                            <th>Cost</th>
                            {this.rowData(port.hourly_cost,true)}
                        </tr>
                        <tr>
                            <th>Revenue</th>
                            {this.rowData(port.hourly_revenue)}
                        </tr>
                        <tr>
                            <th>Profit</th>
                            {this.rowData(port.hourly_profit)}
                        </tr>
                    </table>
                </div>
        )
    }
    render(){
        return (
            <div class='dailypnl'>
                <Tabs defaultActiveKey='0' id='dailypnl-tabs'>
                    {this.state.data.map(portfolio => 
                        <Tab eventKey={portfolio.id} title={portfolio.market}>
                            {this.pnlTable(portfolio)}
                        </Tab>)}
                </Tabs>
            </div>
        )
    }
}
