import React, { Component } from 'react'
import Axios from 'axios';
import * as URL from './URL.js'; 
import NumberFormat from 'react-number-format';

export default class QuarterlyCards extends Component {
    constructor(props){
        super(props);
        this.state ={
            data: []
        }
    }
    getData=()=>{
        Axios.get(URL.GET_QUARTERLY)
            .then(res => {
                console.log(res);
                this.setState({data: res.data});
            });
    }
    componentDidMount() {
        this.getData();
        this.intervalID = setInterval(this.getData.bind(this),60000);
    }
    componentWillUnmount(){
        clearInterval(this.intervalID);
    }
    getProfit=(item)=>{
        if(item>0)
            return <span style={{color: '#0be044'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        else if(item<0)
            return <span style={{color: 'red'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        return <span><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
    }
    card=(data)=>{
        return (
                <div className='card'>
                    <div className='card-heading'>
                        <div>
                            <h1>{data.quarter}</h1>
                        </div>
                    </div>
                    <div className='card-value'>
                        {this.getProfit(Math.round(data.profit))}
                    </div>
                </div>
                )
    }
    render(){
        return (
            <div class='d-flex justify-content-center'>
                {this.state.data.map(quarter =>
                    this.card(quarter)
                )}
            </div>
        )
    }
}
