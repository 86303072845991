import React, { useState } from 'react';
import { HashRouter as Router, Link, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Home from './pages/Home';
import Login from './pages/Login';
import Pnl from './pages/Pnl';
import Reports from './pages/Reports'
import DbHealthHome from './pages/DbHealthHome'
import Bounties from './pages/Bounties'
import { AuthContext } from './context/auth';

function App(props) {
  const existingTokens=JSON.parse(localStorage.getItem("tokens"));
  const [authTokens, setAuthTokens]=useState(existingTokens);
  const setTokens=(data)=>{
    localStorage.setItem('tokens',JSON.stringify(data));
    setAuthTokens(data);
  }
  return(
    <AuthContext.Provider value={{authTokens, setAuthTokens: setTokens}}>
      <Router>
        <div class='body'>
          <Route exact path='/' component={Login}/>
          <Route exact path='/login' component={Login}/>
          <PrivateRoute exact path='/home' component={Home}/>
          <PrivateRoute exact path='/pnl' component={Pnl}/>
          <PrivateRoute exact path='/reports' component={Reports}/>
          <PrivateRoute exact path='/dbhealth' component={DbHealthHome}/>
          <PrivateRoute exact path='/bounties' component={Bounties}/>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
