import React, { Component } from 'react'
import Axios from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';
import * as URL from './URL.js';
import NumberFormat from 'react-number-format';

export default class Bounty extends Component{
    intervalID;
    constructor(props){
        super(props);
        this.state={
            data: []
        }
    }
    componentDidMount(){
        this.getData();
        this.intervalID=setInterval(this.getData.bind(this),60000);
    }
    getData=()=>{
        Axios.get(URL.GET_BOUNTY)
            .then(res =>{
                console.log(res);
                this.setState({data: res.data});
            });
    }
    componentWillUnmount(){
        clearInterval(this.intervalID);
    }
    datadump=(data)=>{
        var prog=0;
        if(data.progress>=0){
            prog=data.progress;
        }
        const percent=prog/data.goal*100;
        return(
            <table>
                <tr>
                    <th>{data.title}</th>
                </tr>
                <tr>
                    <ProgressBar animated now={prog/data.goal*100}/>
                </tr>
                <tr>
                    <td><NumberFormat value={data.progress} displayType={'text'} prefix={'$'} thousandSeparator={true}/> of <NumberFormat value={data.goal} displayType={'text'} prefix={'$'} thousandSeparator={true}/> ({Math.round(data.progress/data.goal*100)}%)</td>
                </tr>
                <tr>
                    <td><i>Expires: {data.deadline}</i></td>
                </tr>
                <tr><br></br></tr>
            </table>
        )
    }
    render(){
        return(
            <div class='reports'>
                <table class='reporttable'>
            {this.state.data.map(entry=>
                this.datadump(entry)
                )}
                </table>
            </div>
        )
    }
}

