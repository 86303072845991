import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import NumberFormat from 'react-number-format';

class Card extends React.Component{
    constructor(props){
        super(props);
        this.state={
            title: props.title,
            profit: props.profit
        };
    }
    getProfit=(item)=>{
        if(item>0)
            return <span style={{color: '#0be044'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        else if(item<0)
            return <span style={{color: 'red'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        return <span><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
    }
    render(){
        return (
                    <div className='card'>
                        <div className='card-heading'>
                            <div>
                                <h1>{this.state.title}</h1>
                            </div>
                        </div>
                        <div className='card-value'>
                            {this.getProfit(this.state.profit)}
                        </div>
                    </div>
                );
            }
}

export default Card