import React, { Component } from 'react'
import Axios from 'axios';
import * as URL from './URL.js';
import {
  ComposedChart, Line, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
  Legend, Scatter,Cell,
} from 'recharts';
import NumberFormat from 'react-number-format';

const CustomToolTip = props => {
  const { active, payload, label } = props;
  if (!active || !payload) {
    return null;
  }
  if(payload[0].value>0 && payload[1].value>0)
    return (
        <div
        className="custom-tooltip"
        >
        <p>
            <strong>{label}</strong>
        </p>
        <p>
            <strong>{payload[0].name}:</strong> <div style={{color: '#0be044'}}><NumberFormat value={Math.abs(payload[0].value)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></div>
        </p>
        <p>
            <strong>{payload[1].name}:</strong> <div style={{color: '#0be044'}}><NumberFormat value={Math.abs(payload[1].value)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></div>
        </p>
        </div>
    );
  else if(payload[0].value<0 && payload[1].value>0)
    return (
        <div
        className="custom-tooltip"
        >
        <p>
            <strong>{label}</strong>
        </p>
        <p>
            <strong>{payload[0].name}:</strong> <div style={{color: 'red'}}><NumberFormat value={Math.abs(payload[0].value)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></div>
        </p>
        <p>
            <strong>{payload[1].name}:</strong> <div style={{color: '#0be044'}}><NumberFormat value={Math.abs(payload[1].value)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></div>
        </p>
        </div>
    );
  else if(payload[0].value>0 && payload[1].value<0)
    return (
        <div
        className="custom-tooltip"
        >
        <p>
            <strong>{label}</strong>
        </p>
        <p>
            <strong>{payload[0].name}:</strong> <div style={{color: '#0be044'}}><NumberFormat value={Math.abs(payload[0].value)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></div>
        </p>
        <p>
            <strong>{payload[1].name}:</strong> <div style={{color: 'red'}}><NumberFormat value={Math.abs(payload[1].value)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></div>
        </p>
        </div>
    );
  else if(payload[0].value<0 && payload[1].value<0)
    return (
        <div
        className="custom-tooltip"
        >
        <p>
            <strong>{label}</strong>
        </p>
        <p>
            <strong>{payload[0].name}:</strong> <div style={{color: 'red'}}><NumberFormat value={Math.abs(payload[0].value)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></div>
        </p>
        <p>
            <strong>{payload[1].name}:</strong> <div style={{color: 'red'}}><NumberFormat value={Math.abs(payload[1].value)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></div>
        </p>
        </div>
    );
return <div></div>
};

export default class RunningTotalChart extends Component {
    state={
        data: []
    };
    componentDidMount() {
        this.getData();
        this.intervalID = setInterval(this.getData.bind(this),60000);
    }
    getData=()=>{
        Axios.get(URL.GET_RUNNINGTOTAL)
            .then(res => {
                console.log(res);
                console.log('DATA REFRESHED @'+Date.now());
                this.setState({data: res.data});
            });
    }
    componentWillUnmount(){
        clearInterval(this.intervalID);
    }
    render() {
        return (
            <div class='pnlchart'>
                <h1 class='pnlchart-header'>Profit and Loss: 2022-{new Date().getFullYear()} </h1>
               <ComposedChart
                    width={1535}
                    height={300}
                    data={this.state.data}
                    margin={{
                    top: 10, right: 30, bottom: 0, left: 30,
                    }}
                >
                <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="date" stroke="white" />
                    <YAxis yAxisId="left" stroke="white" tickFormatter={tick => {
                        return tick.toLocaleString();}}/>
                    <YAxis yAxisId="right" orientation='right' stroke="white" tickFormatter={tick => {
                        return tick.toLocaleString();}}/>
                    <Tooltip content={<CustomToolTip/>} />
                    <Legend />
                    <Area yAxisId="right" type="monotone" dataKey="running_total" fill="#418ce8" stroke="#418ce8" />
                    <Bar yAxisId='left' dataKey='profit'>
                        {this.state.data.map((entry,index) =>(
                            <Cell fill={entry.profit >= 0 ? '#0be044' : 'red'}/>
                        ))}
                    </Bar>
                </ComposedChart> 
            </div>
        )
    }
}
