//import 'bootstrap/dist/css/bootstrap.css';
import React, { Component } from 'react';
import{
    Tabs,
    Tab
} from 'react-bootstrap'
import Axios from 'axios';
import * as URL from './URL.js';
import NumberFormat from 'react-number-format';

export default class DbHealthView extends Component {
    intervalID;
    constructor(props) {
        super(props)
        this.state = {
            //title: props.title,
            //hourly_cost: props.hourly_cost,
            MET_Load_ERCOT_perc50: props.MET_Load_ERCOT_perc50,
            //MET_Load_North-ForecastZone_perc50: props.MET_Load_North-ForecastZone_perc50,
            //"MET_Load_North-ForecastZone_perc50": props."MET_Load_North-ForecastZone_perc50",
            //"MET_Load_South-ForecastZone_perc50": props."MET_Load_South-ForecastZone_perc50",
            MET_Load_Coast_perc50: props.MET_Load_Coast_perc50,
            MET_Load_East_perc50: props.MET_Load_East_perc50,
            MET_West_perc50: props.MET_West_perc50,
            //"MET_Coastal-GeoRegion_perc50": props."MET_Coastal-GeoRegion_perc50",
            //"MET_South-Houston_perc50": props.MET_South_Houston_perc50,
            FarWest: props.FarWest,
            West: props.West,
            STWPF_LZ_WEST: props.STWPF_LZ_WEST
        }
    }
    componentDidMount() {
        this.getData();
        this.intervalID = setInterval(this.getData.bind(this),60000);
    }
    getData=()=>{
        Axios.get(URL.GET_DBHEALTH)
            .then(res => {
                console.log(res);
                console.log('DB DATA REFRESHED @'+Date.now());
                this.setState({data: res.data});
            });
    }
    componentWillUnmount(){
        clearInterval(this.intervalID);
    }
    headers=()=>{
        var i;
        var headers=[<th></th>];
        headers.push(<th>SERIES</th>)
        headers.push(<th>DATETIME</th>)
        return headers
    }
    render() {
        return (
                <div class='pnltable'>
                    <div class='d-flex'>
                        <div class='p-2 flex-fill'><h1>db_health_test</h1></div>
                        //<div class='p-2 flex-fill'><h1>{this.state.title}</h1></div>
                        //<div class='p-2 align-content-end'><h3>Total Cost: {this.totalData(this.state.cost,true)}</h3></div>
                        //<div class='p-2 align-content-end'><h3>Total Revenue: {this.totalData(this.state.revenue)}</h3></div>
                        //<div class='p-2 align-content-end'><h3>Total Profit: {this.totalData(this.state.profit)}</h3></div>
                    </div>
                    <table class='table table-hover'>
                        <thead>
                            <tr>
                                {this.headers()}
                            </tr>
                        </thead>
                        <tr>
                            <th>MET_Load_ERCOT_perc50</th>
                            {this.rowData(this.state.MET_Load_ERCOT_perc50,true)}
                        </tr>
                        //<tr>
                        //    <th>Revenue</th>
                        //    {this.rowData(this.state.hourly_revenue)}
                        //</tr>
                        //<tr>
                        //    <th>Profit</th>
                        //    {this.rowData(this.state.hourly_profit)}
                        //</tr>
                    </table>
                </div>
        )
    }
}
