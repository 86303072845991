import React from 'react';
import {
    Navbar,
    PnlDetail
}
from '../components/Components.js'
import 'bootstrap/dist/css/bootstrap.css';
import '../assets/css/Dashboard.css';

function Pnl(props){
    return (
    <div className='container-fluid'>
        <div class='d-flex justify-content-center'><PnlDetail id={6474}/></div>
    </div>
    );
}

export default Pnl;