import React, { Component } from 'react'
import Axios from 'axios';
import * as URL from './URL.js'; 

export default class PnlReportList extends Component{
    intervalID;
    constructor(props){
        super(props);
        this.state={
            data: []
        }
    }
    componentDidMount(){
        this.getData();
        this.intervalID=setInterval(this.getData.bind(this),60000);
    }
    getData=()=>{
        Axios.get(URL.GET_REPORT_LIST)
            .then(res =>{
                console.log(res);
                this.setState({data: res.data});
            });
    }
    componentWillUnmount(){
        clearInterval(this.intervalID);
    }
    dataentry=(data)=>{
        return(
            <tr>
            <td>{data.filename}</td><td><a href={URL.GET_REPORT+data.filename}>Download</a></td>
            </tr>
        )
    }
    render(){
        return(
            <div class='reports'>
                <table class='reporttable'>
                    <tr><th>PNL Report</th><th></th></tr>
            {this.state.data.map(entry=>
                this.dataentry(entry)
                )}
                </table>
            </div>
        )
    }
}