import React, { Component } from 'react'
import{
    Tabs,
    Tab
} from 'react-bootstrap'
import Axios from 'axios';
import * as URL from './URL.js'; 
import NumberFormat from 'react-number-format';

export default class PnlDetail extends Component{
    intervalID;
    constructor(props){
        super(props);
        this.id=props.id;
        this.state={
            port: [],
            data: []
        }
    }
    componentDidMount(){
        this.getData();
        this.intervalID=setInterval(this.getData.bind(this),60000);
    }
    getData=()=>{
        Axios.post(URL.GET_DETAIL,
            {pid: this.id}
        ).then(trade_result =>{
                this.setState({data: trade_result.data});
            });
        Axios.post(URL.GET_PORTFOLIO,
            {pid: this.id}
        ).then(result =>{
                this.setState({port: result.data});
            });
    }
    componentWillUnmount(){
        clearInterval(this.intervalID);
    }
    headers=()=>{
        var i;
        var headers=[<th>Source</th>,<th>Bid Type</th>,<th></th>,<th>Total</th>];
        for (i=0; i<24; i++){
            headers.push(<th>HE{i+1}</th>)
        }
        return headers
    }
    rowData=(data,invert=false)=>{
        var elements=[];
        elements=data.map(item => {
            if(item>0 && invert===false)
                return <td style={{color: '#0be044'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></td>
            else if(item<0 && invert===false)
                return <td style={{color: 'red'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></td>
            else if (item>0 && invert===true)
                return <td style={{color: 'red'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></td>
            else if (item<0 && invert===true)
                return <td style={{color: '#0be044'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></td>
            return <td><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></td>

        });
        return elements
    }
    tradeData=(data)=>{
        var elements=[<td>{data.source}</td>,<td>{data.bid_type}</td>];
        return elements
    }
    totalData=(item,invert=false)=>{
        if(item>0 && invert===false)
            return <span style={{color: '#0be044'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        else if(item<0 && invert===false)
            return <span style={{color: 'red'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        else if (item>0 && invert===true)
            return <span style={{color: 'red'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        else if (item<0 && invert===true)
            return <span style={{color: '#0be044'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        return <span><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
    }
    pnlTable=()=>{
        return (
                <div class='pnltable'>
                    <div class='d-flex'>
                        <div class='p-2 flex-fill'><h1>{this.state.port.note}</h1></div>
                        <div class='p-2 align-content-end'><h3>Total Cost: {this.totalData(this.state.port.cost,true)}</h3></div>
                        <div class='p-2 align-content-end'><h3>Total Revenue: {this.totalData(this.state.port.revenue)}</h3></div>
                        <div class='p-2 align-content-end'><h3>Total Profit: {this.totalData(this.state.port.profit)}</h3></div>
                    </div>
                    <table class='table table-hover'>
                        <thead>
                            <tr>
                                {this.headers()}
                            </tr>
                        </thead>
                    </table>
                </div>
        )
    }
    render(){
        return(
            <div class='pnltable'>
                <div class='d-flex'>
                    <div class='p-2 flex-fill'><h1>{this.state.port.note}</h1></div>
                    <div class='p-2 align-content-end'><h3>Total Cost: {this.totalData(this.state.port.cost,true)}</h3></div>
                    <div class='p-2 align-content-end'><h3>Total Revenue: {this.totalData(this.state.port.revenue)}</h3></div>
                    <div class='p-2 align-content-end'><h3>Total Profit: {this.totalData(this.state.port.profit)}</h3></div>
                </div>
                <table class='table table-hover'>
                    <thead>
                        <tr>
                            {this.headers()}
                        </tr>
                    </thead>
                    {this.state.data.map(trade =>
                        <tr>
                            {this.tradeData(trade)}
                        </tr>
                    )}
                </table>
            </div>
        )
    }
}