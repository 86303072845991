import React, {useState} from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import '../assets/css/Login.css';
import { Error } from '../components/AuthForm';
import { useAuth } from '../context/auth';
import wallpaper from '../assets/images/traderwp.mp4'

function Login(props){
    const [isLoggedIn, setLoggedIn]=useState(false);
    const [isError, setIsError]=useState(false);
    const [email, setUserName]=useState('');
    const [password, setPassword]=useState('');
    const { setAuthTokens }=useAuth();
    const { authTokens }=useAuth();
    
    function postLogin(){
        axios.post('https://api.grandoakcapital.net/login',{
            email,
            password
        }).then(result =>{
            if(result.status===200){
                setAuthTokens(result.data);
                setLoggedIn(true);
            } else {
                setIsError(true);
            }
        }).catch(e =>{
            setIsError(true);
        });
    }

    if (isLoggedIn || authTokens) {
        return <Redirect to="/home"/>;
    }
    return (
        <div class='container'>
            <video class='wallpapervid' autoPlay muted loop>
                <source src={wallpaper} type='video/mp4'></source>
            </video>
            <div class='box'>
                <h1>GOCPanel</h1>
                <input
                    type='text'
                    value={email}
                    onChange={e =>{
                        setUserName(e.target.value);
                    }}
                    placeholder='email'
                />
                <input
                    type='password'
                    value={password}
                    onChange={e =>{
                        setPassword(e.target.value);
                    }}
                    placeholder='password'
                />
                <input type="submit" name="" value="Login" onClick={postLogin}/>
                    { isError &&<Error>The username or password provided were incorrect!</Error>}
            </div>
        </div>
    );
}

export default Login;