
import React from 'react';
import {
    Navbar,
    Bounty
}
from '../components/Components.js'
import 'bootstrap/dist/css/bootstrap.css';
import '../assets/css/Reports.css';


function Bounties(props){
    return (
    <div className='container-fluid'>
        <div class='d-flex justify-content-center'>
            <Navbar/>
        </div>
        <div class='d-flex justify-content-center'>
            <Bounty/>
        </div>
    </div>
    );
}

export default Bounties;