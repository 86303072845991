import React from 'react'
import {
    Navbar
}
from '../components/Components.js'
import 'bootstrap/dist/css/bootstrap.css';
import '../assets/css/Dashboard.css';
import DailyPnlTable from '../components/DailyPnlTable.js';
import QuarterlyCards from '../components/QuarterlyCards.js';
import RunningTotalChart from '../components/RunningTotalChart.js';

class Dashboard extends React.Component{
    constructor(props){
        super(props);
        this.state={};
    }
    render() {
        return(
            <div className='container-fluid'>
                {/*NAVIGATION BAR*/}
                <div class='d-flex justify-content-center'>
                    <Navbar/>
                </div>

                {/*CHART BOXES*/}
                <div class='d-flex'>
                    <DailyPnlTable/>
                </div>

                {/*CARD ROW*/}
                <div class='d-flex justify-content-center'>
                    <QuarterlyCards/>
                </div>

                <div class='d-flex justify-content-center'>
                    <RunningTotalChart/>
                </div>
            </div>
        );
    }
}

export default Dashboard