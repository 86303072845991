import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import {Button} from '../components/AuthForm';
import { Redirect,Link } from 'react-router-dom';
import { useAuth } from '../context/auth';

function Navbar(props){
    const { setAuthTokens } = useAuth();

    function logout(){
        setAuthTokens(null);
        /*
        return <Redirect to='/'/>
        */
       return
    }
    function test(){
        return <Redirect to='/reports'/>
    }

    return(
            <nav class='navbar navbar-expand-sm'>
                <a class='navbar-brand' href='/#/home'>GOCPanel</a>
                <div class='collapse navbar-collapse' id='topnav'>
                    <ul class='navbar-nav mr-auto'>
                        <li class='navbar-nav mr-auto'>
                            <Link to='/reports'>Reports</Link>
                        </li>
                        <li>||</li>
                        <li class='navbar-nav mr-auto'>
                            <Link to='/bounties'>Bounties</Link>
                        </li>
                    </ul>
                    <ul class='navbar-nav ml-auto'>
                        <li class='nav-item disabled'>
                            <button class='navbutton' onClick={logout}>Logout</button>
                        </li>
                    </ul>
                </div>
            </nav>
    );
}
export default Navbar
