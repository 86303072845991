import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import NumberFormat from 'react-number-format';

export default class PnlTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: props.title,
            hourly_cost: props.hourly_cost,
            hourly_revenue: props.hourly_revenue,
            hourly_profit: props.hourly_profit,
            market: props.market,
            id: props.id,
            note: props.note,
            cost: props.cost,
            revenue: props.revenue,
            profit: props.profit
        }
    }
    headers=()=>{
        var i;
        var headers=[<th></th>];
        for (i=0; i<24; i++){
            headers.push(<th>HE{i+1}</th>)
        }
        return headers
    }
    rowData=(data,invert=false)=>{
        var elements=[];
        elements=data.map(item => {
            if(item>0 && invert===false)
                return <td style={{color: '#0be044'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></td>
            else if(item<0 && invert===false)
                return <td style={{color: 'red'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></td>
            else if (item>0 && invert===true)
                return <td style={{color: 'red'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></td>
            else if (item<0 && invert===true)
                return <td style={{color: '#0be044'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></td>
            return <td><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></td>

        });
        return elements
    }
    totalData=(item,invert=false)=>{
        if(item>0 && invert===false)
            return <span style={{color: '#0be044'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        else if(item<0 && invert===false)
            return <span style={{color: 'red'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        else if (item>0 && invert===true)
            return <span style={{color: 'red'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        else if (item<0 && invert===true)
            return <span style={{color: '#0be044'}}><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
        return <span><NumberFormat value={Math.abs(item)} displayType={'text'} prefix={'$'} thousandSeparator={true}/></span>
    }
    render() {
        return (
                <div class='pnltable'>
                    <div class='d-flex'>
                        <div class='p-2 flex-fill'><h1>{this.state.title}</h1></div>
                        <div class='p-2 align-content-end'><h3>Total Cost: {this.totalData(this.state.cost,true)}</h3></div>
                        <div class='p-2 align-content-end'><h3>Total Revenue: {this.totalData(this.state.revenue)}</h3></div>
                        <div class='p-2 align-content-end'><h3>Total Profit: {this.totalData(this.state.profit)}</h3></div>
                    </div>
                    <table class='table table-hover'>
                        <thead>
                            <tr>
                                {this.headers()}
                            </tr>
                        </thead>
                        <tr>
                            <th>Cost</th>
                            {this.rowData(this.state.hourly_cost,true)}
                        </tr>
                        <tr>
                            <th>Revenue</th>
                            {this.rowData(this.state.hourly_revenue)}
                        </tr>
                        <tr>
                            <th>Profit</th>
                            {this.rowData(this.state.hourly_profit)}
                        </tr>
                    </table>
                </div>
        )
    }
}
