import React from 'react';
import {
    Navbar,
    PnlReportList
}
from '../components/Components.js'
import 'bootstrap/dist/css/bootstrap.css';
import '../assets/css/Reports.css';


function Reports(props){
    return (
    <div className='container-fluid'>
        <div class='d-flex justify-content-center'>
            <Navbar/>
        </div>
        <div class='reportlist'>
            <PnlReportList/>
        </div>
    </div>
    );
}

export default Reports;