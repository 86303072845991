/*export const GET_DAILY='http://45.32.199.35/pnl/daily'
export const GET_QUARTERLY='http://45.32.199.35/pnl/quarterly'
export const GET_CURRENTYEAR='http://45.32.199.35/pnl/currentyear'
export const GET_RUNNINGTOTAL='http://45.32.199.35/pnl/runningtotal'
*/
export const GET_DAILY='https://api.grandoakcapital.net/pnl/daily'
export const GET_QUARTERLY='https://api.grandoakcapital.net/pnl/quarterly'
export const GET_CURRENTYEAR='https://api.grandoakcapital.net/pnl/currentyear'
export const GET_RUNNINGTOTAL='https://api.grandoakcapital.net/pnl/runningtotal'
export const GET_DETAIL='https://api.grandoakcapital.net/pnl/detail'
export const GET_PORTFOLIO='https://api.grandoakcapital.net/pnl/portfolio'
export const GET_REPORT_LIST='https://api.grandoakcapital.net/reports/pnl_report_list'
export const GET_REPORT='https://api.grandoakcapital.net/reports/'
export const GET_DBHEALTH='https://api.grandoakcapital.net/dbhealth'
export const GET_BOUNTY='https://api.grandoakcapital.net/bountylist'
